import * as Sentry from '@sentry/gatsby';
import { isProd } from './config/deploy-env';

Sentry.init({
  dsn: 'https://d9f30c4641c04f93abd531b9f8514fff@o80434.ingest.sentry.io/6155007',
  environment: isProd ? 'prod' : 'staging',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration()
  ],
  sendDefaultPii: true,
  release: isProd ? process.env.COMMIT_REF : undefined,
  tracesSampleRate: isProd ? 0.00001 : 1.0,
  // Ignore errors that come from places that are not Own Up.
  allowUrls: [/https?:\/\/(\S*\.)?ownup\.com/],
  // Ignore errors related to cache invalidation.
  ignoreErrors: [/Loading chunk \d+ failed/],
  // Session Replay
  replaysOnErrorSampleRate: 1.0 // 100% sampling when errors occur
});
