import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { FaqData, FaqDatum } from '../../components/faq/types';
import { PortableTextComponent } from '../../components/portable-text';
import { breadCrumbsData } from '../content/breadCrumbs';
// import { faqWithAnswers } from '../content/faq/faq';

const homeUrl = process.env.GATSBY_WWW;

const renderToStaticMarkup = ReactDOMServer.renderToStaticMarkup;

export const organzationSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Own Up',
  url: 'https://www.ownup.com/',
  logo: 'https://s3-us-west-2.amazonaws.com/cbi-image-service-prd/modified/abc331da-3dce-47b6-b5d2-577db4076ece.png',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '(844) 947-2848',
    contactType: 'customer service',
    contactOption: 'TollFree',
    areaServed: 'US',
    availableLanguage: ['en']
  },
  sameAs: [
    'https://www.facebook.com/weareownup/',
    'https://twitter.com/weareownup/',
    'https://instagram.com/weareownup/',
    'https://pinterest.com/weareownup/'
  ]
});

const renderBlockContent = (faq: FaqDatum) =>
  renderToStaticMarkup(<PortableTextComponent blocks={faq?.answer?.body} />);

export const faqSchema = (faqData: FaqData) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqData.map((faq: FaqDatum) => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: renderBlockContent(faq)
    }
  }))
});

const breadCrumbSchema = (url: string, pathname: string) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  '@id': url,
  itemListElement: [
    { '@type': 'ListItem', position: 1, name: 'Home', item: homeUrl + '/' },
    ...breadCrumbsData[pathname].map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 2,
      name: breadcrumb.title,
      item: breadcrumb.path.includes('https') ? breadcrumb.path : homeUrl + breadcrumb.path
    }))
  ]
});

export const schemaGenerator = (url: string, pathname: string, faqData: FaqData) => {
  const schema = [];
  const schemasList: Record<string, string[]> = {
    faq: ['/faq/'],
    organization: ['/'],
    breadCrumbs: Object.keys(breadCrumbsData)
  };

  for (const key in schemasList) {
    if (schemasList.hasOwnProperty(key) && schemasList[key].includes(pathname)) {
      switch (key) {
        case 'faq':
          if (faqData) {
            schema.push(faqSchema(faqData));
          }
          break;
        case 'organization':
          schema.push(organzationSchema());
          break;
        case 'breadCrumbs':
          schema.push(breadCrumbSchema(url, pathname));
          break;
        default:
          break;
      }
    }
  }

  return schema;
};
