import React from 'react';
import PortableText from 'react-portable-text';

import { replaceAnchorTag } from './elements';
import { replaceHeadingsMap } from './typography';

const SampleImageComponent = (value: any) => {
  return (
    <p>
      <img src={value?.asset?.url || ''} alt={value.alt || ' '} loading="lazy" />
    </p>
  );
};
export const PortableTextComponent = ({ blocks }: { blocks: object[] }) => (
  <PortableText
    // Pass in block content straight from Sanity.io
    content={blocks}
    serializers={{
      h1: ({ children }: { children: React.ReactNode }) => replaceHeadingsMap.h1({}, children),
      h2: ({ children }: { children: React.ReactNode }) => replaceHeadingsMap.h2({}, children),
      h3: ({ children }: { children: React.ReactNode }) => replaceHeadingsMap.h3({}, children),
      link: ({ href, children }: { href: string; children: any }) =>
        replaceAnchorTag(href, children),
      authorReference: ({ node }: any) => <span>{node?.author?.name}</span>,
      mainImage: SampleImageComponent
    }}
    projectId={process.env.GATSBY_SANITY_PROJECT_ID}
    dataset={process.env.GATSBY_SANITY_DATASET}
  />
);
