import React from 'react';
/**
 * Functions for parsing data from Sanity CMS.
 */

export const getRequiredFields = (data: Record<string, any>, types: string[]) => {
  const fieldsObj: Record<string, any> = {};

  types.forEach((type: string) => {
    const matchingObjects = data.filter((obj: any) => obj._type === type);
    if (matchingObjects.length > 0) {
      for (let i = 0; i < matchingObjects.length; i++) {
        const key = i === 0 ? `${type}Obj` : `${type}Obj${i}`;
        fieldsObj[key] = matchingObjects[i];
      }
    }
  });

  return fieldsObj;
};

export const mapPageData = (pageSections: Record<string, any>) => {
  let sections = {};
  pageSections.forEach((section: Record<string, any>) => {
    sections = {
      ...sections,
      [section.name]: (section._rawPageBuilder?.length > 0 && [...section._rawPageBuilder]) || []
    };
  });
  return sections;
};

export const getPageSections = (data: Record<string, any>) => {
  if (data?.pages?.nodes?.length && data?.pages?.nodes[0].pageBuilder?.length) {
    return mapPageData(data.pages.nodes[0].pageBuilder);
  }
  return {};
};

export const formatIconMultipleData = (data: Record<string, any>) => {
  const formattedData = data.pageBuilder.map((element: Record<string, any>) => {
    const requiredTypes = ['heading', 'tagline', 'mainImage'];
    const elementContent = getRequiredFields(element.pageBuilder, requiredTypes);
    const { headingObj, taglineObj, mainImageObj } = elementContent;
    return {
      icon: serializeToImageObject(mainImageObj, {}),
      header: headingObj?.heading,
      body: taglineObj?.tagline
    };
  });

  return formattedData;
};

export const formatImageMultipleData = (data: Record<string, any>) => {
  const formattedData = data.pageBuilder.map((element: Record<string, any>) => {
    const requiredTypes = ['heading', 'tagline', 'mainImage'];
    const elementContent = getRequiredFields(element.pageBuilder, requiredTypes);
    const { headingObj, taglineObj, mainImageObj } = elementContent;

    return {
      image: serializeToImageObject(mainImageObj, { style: { width: '193px' } }),
      data: {
        header: headingObj?.heading,
        body: taglineObj?.tagline
      }
    };
  });

  return formattedData;
};

export const serializeToImageObject = (
  mainImageObj: Record<string, any>,
  options: { Placeholder?: string; style?: Record<string, any> }
) => {
  return <img src={mainImageObj?.asset?.url || ''} alt={mainImageObj?.alt || ''} {...options} />;
};
