const trigger = process.env.CODEBUILD_WEBHOOK_TRIGGER || '';

const mPR = /^pr\/(.+)$/g.exec(trigger);
const isStorybook = process.env.URL && process.env.URL.indexOf('storybook') > -1;
const isStaging = process.env.URL && process.env.URL.indexOf('staging') > -1;

module.exports = {
  isPR: !!mPR,
  branch: (process.env.CODEBUILD_WEBHOOK_HEAD_REF || '').replace('refs/heads/', ''),
  isStorybook,
  isStaging,
  isProd: !isStorybook && !isStaging
};
