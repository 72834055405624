import { PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const WWW = 'www.ownup.com';
const MY = 'my.ownup.com';
const ONBOARDING = 'onboarding.ownup.com';
const OLD_BLOG = 'resources.ownup.com';
const BLOG_PREFIX = '/learn';

const Link = styled.a`
  color: ${({ theme }: PropsWithTheme<{}>) => theme.underlineLinkColors.linkFontColor};
  text-decoration: none;
`;

const replaceHostUrl = (href: string) => {
  const url = new URL(href);
  return url.pathname;
};

const getDestination = (href?: string) => {
  if (!href) {
    return `${process.env.GATSBY_ONBOARDING}`;
  }

  if (href[0] === '/') {
    return href;
  }

  try {
    const { hostname, pathname } = new URL(href);

    switch (hostname) {
      case WWW:
        return (process.env.GATSBY_WWW || WWW) + pathname;
      case ONBOARDING:
        return (process.env.GATSBY_ONBOARDING || ONBOARDING) + pathname;
      case MY:
        return (process.env.GATSBY_MY || MY) + pathname;
      case OLD_BLOG:
        return replaceHostUrl(href);
      default:
        return href;
    }
  } catch (err) {
    console.log(err);
    return href;
  }
};

const getUrlString = (destination: string) => {
  return destination?.length && destination[0] === '/'
    ? `${BLOG_PREFIX}${destination}/`
    : destination;
};

const LinkWithTracking = (href: string, children: React.ReactNode) => {
  const destination = getDestination(href);

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();

    // Hope that it only takes 300ms for the 1PT server to respond.
    // Wait time guided by Mixpanel's definition for a similar tracking function:
    // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack_links
    setTimeout(() => {
      if (typeof window !== 'undefined' && destination) {
        const urlString =
          destination[0] === '/'
            ? `${window.location.origin}${BLOG_PREFIX}${destination}/`
            : destination;

        // Ideally, we wouldn't need to add a utm parameter,
        // but until we have more robust tracking, it's good to do this just in case.
        const url = new URL(urlString);

        window.location.href = url.href;
      }
    }, 300);

    return false;
  };

  return (
    /* eslint-disable-next-line react/jsx-no-target-blank */
    <Link href={getUrlString(destination || '')} onClick={onClick} target="_blank">
      {children}
    </Link>
  );
  // };

  // return Anchor;
};

export const replaceAnchorTag = (href: string, children: React.ReactNode) =>
  LinkWithTracking(href, children);
