import { SplitWrapper } from '@rategravity/marketing-components';
import React from 'react';
import { PageView } from './src/components/page-view';
import { SEO } from './src/components/seo';
import { metadata } from './src/data/metadata/pages';

// Paths to not build for SSR
const pagesToSkip = ['/realtor-csma-nc/', '/reviews/'];

export const PageWrapper = ({
  element,
  props: {
    location: { pathname }
  }
}) => {
  if (typeof window === 'undefined' && pagesToSkip.includes(pathname)) {
    console.log('Skipping build for ', pathname);
    return <div />;
  }

  return (
    <React.Fragment>
      <SplitWrapper />
      {process.env.STORYBOOK_ENV === 'true' ? null : <SEO {...metadata[pathname]} />}
      <PageView>{element}</PageView>
    </React.Fragment>
  );
};
