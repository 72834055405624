export const breadCrumbsData: Record<string, { title: string; path: string }[]> = {
  '/how-it-works/': [{ title: 'How it works', path: '/how-it-works/' }],
  '/about/': [{ title: 'About', path: '/about/' }],
  '/faq/': [{ title: 'FAQS', path: '/faq/' }],
  '/reviews/': [{ title: 'Reviews', path: '/reviews/' }],
  '/licensing/': [{ title: 'Licensing', path: '/licensing/' }],
  '/privacy/': [{ title: 'Privacy', path: '/privacy/' }],
  '/terms-conditions/': [{ title: 'Terms and Conditions', path: '/terms-conditions/' }],
  '/real-estate/': [{ title: 'Real Estate', path: '/real-estate/' }],
  '/providers/': [{ title: 'Providers', path: '/providers/' }],
  '/lender-partners/': [{ title: 'Lender Partners', path: '/lender-partners/' }],
  '/methodology/': [{ title: 'Savings methodology', path: '/methodology/' }]
};
