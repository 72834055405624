import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { schemaGenerator } from '../../data/metadata/schema';
import { getPageSections, getRequiredFields } from '../../modules/handle-sanity-data';
import { Page } from '../../modules/sanity-data-types';

interface SEOProps {
  title: string;
  description: string;
}

interface Query {
  site: {
    siteMetadata: {
      defaultTitle: string;
      titleTemplate: string;
      defaultDescription: string;
      siteUrl: string;
      name: string;
    };
  };
  pages: {
    nodes: Page[];
  };
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        name
      }
    }
    pages: allSanityPage(filter: { title: { eq: "FAQS" } }) {
      nodes {
        pageBuilder {
          name
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`;

export const SEO = ({ title, description }: SEOProps) => {
  const { pathname } = useLocation();
  const { site, pages }: Query = useStaticQuery(query);

  const pageSections: Record<string, any> = getPageSections({ pages });
  const types: string[] = ['faqs'];
  const content = pageSections?.section1 ? getRequiredFields(pageSections?.section1, types) : {};

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl, name } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`
  };

  // Alphabetical by name.
  const meta = [
    { name: 'description', content: seo.description },
    { name: 'title', content: `${seo.title} | Own Up` },
    { name: 'og:description', content: seo.description },
    { name: 'og:site_name', content: name },
    { name: 'og:type', content: pathname ? 'article' : 'website' },
    { name: 'og:url', content: seo.url },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: `${seo.title} | Own Up` },
    { name: 'twitter:description', content: seo.description },
    { name: 'referrer', content: 'no-referrer' }
  ];

  const schemas = schemaGenerator(seo.url, pathname, content?.faqsObj?.pageBuilder || []);

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate} meta={meta}>
      <html lang="en" />
      {schemas.map((schema: object, index: number) => {
        return (
          <script key={index} type="application/ld+json">
            {JSON.stringify(schema)}
          </script>
        );
      })}
      <link
        rel="canonical"
        key={seo.url}
        href={seo.url}
        data-baseprotocol="https:"
        data-basehost="www.ownup.com"
      />
    </Helmet>
  );
};
